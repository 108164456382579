import { type AppType, type AppProps } from "next/app";

// import { env } from "@/env.mjs";
import { MantineProvider, localStorageColorSchemeManager } from "@mantine/core";
import { theme } from "@/utils";
import { Notifications } from "@mantine/notifications";
// import { useSyncLanguage } from "ni18n";
import { Analytics } from "@vercel/analytics/react";
// import { SpeedInsights } from "@vercel/speed-insights/next";
import { LOCAL_STORAGE_LANG_KEY } from "@/utils";
import { DatesProvider } from "@mantine/dates";
import { createI18n } from "@/utils/i18n";
import { I18nextProvider } from "react-i18next";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

// Dayjs plugins
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

import "@mantine/core/styles.css";
import "mantine-datatable/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
// import "@/styles/globals.css";

import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import { useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
// import { getLocale } from "next-intl/server";

const getLocale = () => {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window !== "undefined") {
    const locale = window?.localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
    if (!!locale) {
      return locale;
    } else {
      window?.localStorage.setItem(LOCAL_STORAGE_LANG_KEY, "es");
    }
  }
  return "es";
};

// const isProd = env.NEXT_PUBLIC_SITE_ENV === "production";

const colorSchemeManager = localStorageColorSchemeManager({
  key: "alma-med-color-scheme",
});

const App: AppType = ({ Component, pageProps }: AppProps) => {
  const i18n = useMemo(() => createI18n(), []);
  useEffect(() => {
    const locale = window?.localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
    if (locale) {
      void i18n.changeLanguage(locale);
    }
  }, []);

  // useEffect(() => {
  //   if (isProd) {
  //     TagManager.initialize({
  //       gtmId: "GTM-T4BRNJCT",
  //     });
  //   } else {
  //     console.info(
  //       "[GTM]: Skip Google Tag Manager during non-productive instance"
  //     );
  //   }
  // }, []);
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <MantineProvider
          theme={theme}
          defaultColorScheme="light"
          colorSchemeManager={colorSchemeManager}
        >
          {/* <DatesProvider settings={{ locale: getLocale() }}> */}
          <DatesProvider settings={{ locale: i18n.language }}>
            <Component {...pageProps} />
          </DatesProvider>
          <Notifications />
        </MantineProvider>
      </I18nextProvider>
      <Analytics />
      {/* <SpeedInsights /> */}
    </>
  );
};

export default App;
