// import i18n from "i18next";
import i18next, { type Resource } from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend";
import en from "../../public/locales/en/translation.json";
import es from "../../public/locales/es/translation.json";
import pt from "../../public/locales/pt/translation.json";

export const LOCALES = [
  {
    id: 1,
    name: "English",
    tag: "en",
    originalName: "English",
    flagEmoji: "🇬🇧",
    translation: en,
  },
  {
    id: 2,
    name: "Spanish",
    tag: "es",
    originalName: "Spanish",
    flagEmoji: "🇬🇧",
    translation: es,
  },
  {
    id: 3,
    name: "Português",
    tag: "pt",
    originalName: "Português",
    flagEmoji: "🇬🇧",
    translation: pt,
  },
];
const resources = LOCALES.reduce<Resource>(
  (acc, { tag, translation }) => ({
    ...acc,
    [tag]: { translation: translation },
  }),
  {}
);

// This is a simple i18n configuration with English and French translations.
// You can find the translations in web/src/locales/{language}.json
// see: https://react.i18next.com
// Here's an example of how to use it in your components, pages or layouts:
/*
import { Link, routes } from '@redwoodjs/router'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <h1>{t('HomePage.title')}</h1>
      <button onClick={() => i18n.changeLanguage('fr')}>fr</button>
      <button onClick={() => i18n.changeLanguage('en')}>en</button>
      <p>
        {t('HomePage.info')} <code>./web/src/pages/HomePage/HomePage.js</code>
      </p>
      <p>
        {t('HomePage.route')} <code>home</code>, {t('HomePage.link')}`
        <Link to={routes.home()}>Home</Link>`
      </p>
    </>
  )
}

export default HomePage
*/

// void i18n
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   // .use(LanguageDetector)
//   .use(XHR)
//   .use(initReactI18next)
//   .init({
//     interpolation: { escapeValue: false }, // React already does escaping
//     // fallbackLng: "es", // TODO: remove fallback once multilang is ok
//     resources: {
//       en: {
//         translation: en,
//       },
//       es: {
//         translation: es,
//       },
//     },
//   });
// export default i18n;

export const createI18n = () => {
  const i18n = i18next.createInstance();
  void i18n
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(XHR)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      interpolation: { escapeValue: false }, // React already does escaping
      fallbackLng: "es", // TODO: remove fallback once multilang is ok
      resources,
    });

  return i18n;
};
