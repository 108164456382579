import { unit as Unit } from "mathjs";
import { type UnitNum } from "@/types";

export function un(unitNum?: UnitNum | null) {
  if (
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    !unitNum ||
    typeof unitNum?.value === "undefined" ||
    typeof unitNum?.unit === "undefined"
  ) {
    return null;
  }
  let u;
  if (unitNum?.unit) {
    u = Unit(unitNum.value, unitNum.unit);
  } else {
    u = Unit(String(unitNum.value));
  }
  return u;
}
